import { useState, useEffect } from "react";
import { Slider, Modal } from "@mui/material";
import hsrclogo from "../../images/hsrc-logo-reverse.png";
import citieschairlogo from "../../images/city-chair-logo-sidebar.png";
import csplogo from "../../images/csp_logo.png";
import DisplayMap from "./DisplayMap";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import swal from "sweetalert";
// import hexdata from "../../data/Metros_hex.geo.json";
// import metrodata from "../../data/SA_Metros.geo.json";
// import districtData from "../../data/DistrictMunicipalities_2018.geo.json";
// import provinceData from "../../data/Province_2018.geo.json";
import cloneDeep from "lodash/cloneDeep";
import { ageGroupOptions, exportStatusOptions, firmSizeFTEOptions, firmSizeSARSOptions, sexOptions, immigrationOptions, importStatusOptions, monthOptions, multiEstablishmentOptions, wageBandOptions, youthOptions, youthAltOptions, establishmentSizeFTEOptions } from "../../shared/constants";
import { Box } from "@mui/system";
import { useGeoJSON } from "../../shared/hooks";

// const hexdata = lazy(() => import('../../data/Metros_hex.geo.json'));
// const metrodata = lazy(() => import('../../data/SA_Metros.geo.json'));
// const districtData = lazy(() => import('../../data/DistrictMunicipalities_2018.geo.json'));
// const provinceData = lazy(() => import('../../data/Province_2018.geo.json'));

function TaxDataFiltersForm() {
  // Use the useGeoJSON hook to load the GeoJSON data, this can also return a loading state if needed
  const { geoData: hexdata } = useGeoJSON("Metros_hex.geo.json");
  const { geoData: metrodata } = useGeoJSON("SA_Metros.geo.json");
  const { geoData: districtData } = useGeoJSON("DistrictMunicipalities_2018.geo.json");
  const { geoData: provinceData } = useGeoJSON("Province_2018.geo.json");

  const [filterConfig, setFilterConfig] = useState([]);
  const [showSidebarLeft, setShowSidebarLeft] = useState(true);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [areaSelected, setAreaSelected] = useState(false);
  const [outputSelected, setOutputSelected] = useState(false);
  const [aggregateVariablesSelected, setAggregateVariablesSelected] = useState(false);
  const [viewAsSelected, setViewAsSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [secondYearSelected, setSecondYearSelected] = useState(false);
  const [secondMonthSelected, setSecondMonthSelected] = useState(false);
  const [sexSelected, setSexSelected] = useState(false);
  const [youthAltSelected, setYouthAltSelected] = useState(false);
  const [mapData, setmapData] = useState({});
  const [mapDataGrey, setmapDataGrey] = useState({});
  const [maxValue, setmaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [listMetros, setlistMetros] = useState([]);
  const [listYears, setlistYears] = useState([]);
  const [listOutput, setlistOutput] = useState([]);
  const [listSpatial, setlistSpatial] = useState([]);
  const [listTemporal, setlistTemporal] = useState([]);
  const [listAggregate, setlistAggregate] = useState([]);
  const [listViewas, setlistViewas] = useState([]);
  const [listIndustries, setListIndustries] = useState([]);
  const [selectedMetro, setSelectedMetro] = useState();
  const [selectedOutput, setSelectedOutput] = useState();
  const [selectedSpatial, setSelectedSpatial] = useState();
  const [selectedTemporal, setSelectedTemporal] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [secondSelectedYear, setSecondSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [secondSelectedMonth, setSecondSelectedMonth] = useState();
  const [selectedAggregate, setSelectedAggregate] = useState("unselected");
  const [selectedViewAs, setSelectedViewAs] = useState("");
  const [highestIndustryLevelAvailable, setHighestIndustryLevelAvailable] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedIndustryLevel, setSelectedIndustryLevel] = useState(0);
  const [selectedWageBand, setSelectedWageBand] = useState([0, wageBandOptions.length - 1]);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState([0, ageGroupOptions.length - 1]);
  const [selectedYouth, setSelectedYouth] = useState([0, youthOptions.length - 1]);
  const [selectedYouthAlt, setSelectedYouthAlt] = useState("");
  const [selectedSex, setSelectedSex] = useState("");
  const [selectedFirmSizeSARS, setSelectedFirmSizeSARS] = useState("None");
  const [selectedMultiEst, setSelectedMultiEst] = useState("None");
  const [selectedImmigration, setSelectedImmigration] = useState("None");
  const [selectedFirmSizeFTE, setSelectedFirmSizeFTE] = useState([0, firmSizeFTEOptions.length - 1]);
  const [selectedExportStatus, setSelectedExportStatus] = useState("None");
  const [selectedImportStatus, setSelectedImportStatus] = useState("None");
  const [applicableTaxdataModelName, setApplicableTaxdataModelName] = useState();
  const [displayedViewAs, setDisplayedViewAs] = useState();
  const [displayedMetro, setDisplayedMetro] = useState();
  const [displayedOutput, setDisplayedOutput] = useState();
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opacityLevel, setOpacityLevel] = useState(0.3);
  const [colourToggle, setColourToggle] = useState(true);
  const [extrudeToggle, setExtrudeToggle] = useState(false);
  const [displayConfig, setDisplayConfig] = useState(false);
  const [reloadReport, setreloadReport] = useState(0);
  const isDivergingView = selectedViewAs?.includes("Change");

  useEffect(() => {
    async function loadData() {
      try {
        const response = await axiosNoAuthInstance.get("/api/filterconfig/");
        setFilterConfig(response.data);
      } catch (e) {
        swal("Failed", "Unable to connect with server", "error", {
          buttons: false,
          timer: 2000,
        });
      }

      // get list of metros for dropdown using Metro_firms table
      try {
        const response_metros = await axiosNoAuthInstance.get("/api/municipality-list/?category=A");
        let metro_list = [];
        // transformKeys(response_metros.data,'name','municname');
        for (let i = 0; i < response_metros.data.length; i++) {
          metro_list.push(response_metros.data[i]);
        }
        setlistMetros(metro_list);
      } catch (e) {
        console.log(e);
      }
      // get list of metros for dropdown using Metro_firms table
      try {
        const response_years = await axiosNoAuthInstance.get("/api/metro-year/");
        let list_years = [];
        for (let i = 0; i < response_years.data.length; i++) {
          list_years.push(response_years.data[i].taxyear);
        }
        setlistYears(list_years);
      } catch (e) {
        console.log(e);
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    if (filterConfig?.length > 0) {
      const initlistOutput = getOutputList();
      const initlistSpatial = get_spatial_list(initlistOutput[0]);
      const initlistTemporal = get_temporal_list(initlistOutput[0], initlistSpatial[0]);
      const initlistYear = listYears;
      const initlistAggregate = get_aggregate_list(initlistOutput[0], initlistSpatial[0], initlistTemporal[0]);
      const initlistViewas = get_viewas_list(initlistOutput[0], initlistSpatial[0], initlistTemporal[0], initlistAggregate[0]);
      setlistOutput(initlistOutput);
      setlistSpatial(initlistSpatial);
      setlistTemporal(initlistTemporal);
      setlistYears(initlistYear);
      setlistAggregate(initlistAggregate);
      setlistViewas(initlistViewas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConfig]);

  useEffect(() => {
    if (selectedMetro && selectedOutput) {
      const matchingModelMetro = selectedMetro?.cat_b?.toLowerCase() === "all" ? "Municipality" : selectedMetro?.municname === "Province" || selectedMetro?.municname === "District" ? selectedMetro?.municname : "Hexagon";
      const taxdataModel = filterConfig.find((option) => option?.spatial_variable === matchingModelMetro && option?.output_variable === selectedOutput && option?.temporal_variable === selectedTemporal && option?.aggregate_variable === selectedAggregate);
      setApplicableTaxdataModelName(taxdataModel?.taxdata_modelname);

      if (selectedOutput === "Gini Coefficient") {
        const giniTaxdataModel = filterConfig.find((option) => option?.spatial_variable === matchingModelMetro && option?.output_variable === selectedOutput);
        setApplicableTaxdataModelName(giniTaxdataModel?.taxdata_modelname);
      }

      // Taxdata Model name approach
      if (taxdataModel?.taxdata_modelname?.includes("industry")) {
        const numbers = taxdataModel?.taxdata_modelname.match(/industry_(\d+)d/g)?.map((match) => parseInt(match.slice(9, -1))) ?? [];
        const highestNumber = Math.max(...numbers);
        setHighestIndustryLevelAvailable(highestNumber);
      }
    }
  }, [filterConfig, selectedMetro, selectedOutput, selectedTemporal, selectedAggregate]);

  async function getIndustryList(industryLevel, industryCode) {
    let industryList = [...listIndustries];
    industryList.length = industryLevel - 1;
    try {
      const response = await axiosNoAuthInstance.get("/api/sic-codes/", { params: { nextlevel: industryLevel, prevcode: industryCode } });
      if (response.data.length > 0) {
        industryList[industryLevel - 1] = [{ code: "", activity: "None" }, ...response.data];
      }
      setListIndustries(industryList);
    } catch (e) {
      console.log(e);
      setListIndustries(industryList);
    }
  }

  const clearIndustryList = () => {
    if (listIndustries.length > 0) {
      let industryList = [...listIndustries];
      industryList.length = 1;
      setListIndustries(industryList);
      setSelectedIndustry([]);
      setSelectedIndustryLevel(0);
    }
  };

  const clearSecondYear = () => {
    setSecondSelectedYear();
  };

  const clearSecondMonth = () => {
    setSecondSelectedMonth();
  };

  function clearAdditionalFilters() {
    clearIndustryList();
    setSelectedWageBand([0, wageBandOptions.length - 1]);
    setSelectedAgeGroup([0, wageBandOptions.length - 1]);
    setSelectedYouth([0, youthOptions.length - 1]);
    setSelectedYouthAlt("");
    setSelectedSex("");
    setSelectedFirmSizeSARS("None");
    setSelectedMultiEst("None");
    setSelectedImmigration("None");
    setSelectedFirmSizeFTE([0, firmSizeFTEOptions.length - 1]);
    setSelectedExportStatus("None");
    setSelectedImportStatus("None");
    setSecondSelectedYear();
    setSecondSelectedMonth();
  }

  const handleAgeChange = (event, newValue) => {
    setSelectedAgeGroup(newValue);
    setFiltersApplied(false);
  };

  const handleYouthChange = (event, newValue) => {
    setSelectedYouth(newValue);
    setFiltersApplied(false);
  };

  const handleWageBandChange = (event, newValue) => {
    setSelectedWageBand(newValue);
    setFiltersApplied(false);
  };

  const handleFirmSizeFTEChange = (event, newValue) => {
    setSelectedFirmSizeFTE(newValue);
    setFiltersApplied(false);
  };

  function getOutputList() {
    const vret = [];
    const sortedList = filterConfig.sort((a, b) => a.output_order_number - b.output_order_number);
    for (let i = 0; i < sortedList.length; i++) {
      if (sortedList[i].req_map && vret.indexOf(sortedList[i].output_variable) === -1 && sortedList[i].output_variable !== "SupplementaryData" && sortedList[i].output_variable !== "ShapeFiles") {
        vret.push(sortedList[i].output_variable);
      }
    }
    return vret;
  }

  function get_spatial_list(outputSel) {
    const vret = [];
    for (let i = 0; i < filterConfig.length; i++) {
      if (filterConfig[i].req_map && filterConfig[i].output_variable === outputSel && vret.indexOf(filterConfig[i].spatial_variable) === -1) {
        vret.push(filterConfig[i].spatial_variable);
      }
    }
    return vret;
  }
  function get_spatial_list_from_muncipality(metroSel) {
    // Approach for where no spatial selection is made
    const vret = [];
    metroSel?.municname === "All" ? vret.push("Municipality") : metroSel?.municname === "District" || metroSel?.municname === "Province" ? vret.push(metroSel?.municname) : vret.push("Hexagon");
    return vret;
  }
  function get_temporal_list(outputSel, spatialSel) {
    const vret = [];
    const sortedList = filterConfig.sort((a, b) => a.temporal_order_number - b.temporal_order_number);
    for (let i = 0; i < sortedList.length; i++) {
      if (sortedList[i].req_map && sortedList[i].output_variable === outputSel && sortedList[i].spatial_variable === spatialSel && vret.indexOf(sortedList[i].temporal_variable) === -1) {
        vret.push(sortedList[i].temporal_variable);
      }
    }
    return vret;
  }
  function get_aggregate_list(outputSel, spatialSel, temporalSel) {
    let vret = [];
    const sortedList = filterConfig.sort((a, b) => a.aggregate_order_number - b.aggregate_order_number);
    for (let i = 0; i < sortedList.length; i++) {
      if (sortedList[i].req_map && sortedList[i].output_variable === outputSel && sortedList[i].spatial_variable === spatialSel && sortedList[i].temporal_variable === temporalSel && sortedList[i].aggregate_variable != null && sortedList[i].aggregate_variable !== "" && vret.indexOf(sortedList[i].aggregate_variable) === -1) {
        vret.push(sortedList[i].aggregate_variable);
      }
    }
    return vret;
  }
  function get_viewas_list(outputSel, spatialSel, temporalSel, aggregateSel) {
    let vret = [];
    for (let i = 0; i < filterConfig.length; i++) {
      const spacialVariableCheck = spatialSel === "Hexagon" || spatialSel === "Municipality" ? spatialSel : selectedMetro?.cat_b;
      if (filterConfig[i].req_map && filterConfig[i].output_variable === outputSel && filterConfig[i].spatial_variable === spacialVariableCheck && filterConfig[i].temporal_variable === temporalSel && filterConfig[i].aggregate_variable === aggregateSel && filterConfig[i].viewas_map != null && vret.indexOf(filterConfig[i].viewas_map) === -1) {
        vret = filterConfig[i].viewas_map.split(", ");
      }
    }
    return vret;
  }

  function handleMetroSelect(e) {
    setAreaSelected(true);
    let currentMetro;
    if (e === "All") {
      currentMetro = { cat_b: "All", municname: "All" };
      setSelectedMetro({ cat_b: "All", municname: "All" });
    } else if (e === "Province") {
      currentMetro = { cat_b: "Province", municname: "Province" };
      setSelectedMetro({ cat_b: "Province", municname: "Province" });
    } else if (e === "District") {
      currentMetro = { cat_b: "District", municname: "District" };
      setSelectedMetro({ cat_b: "District", municname: "District" });
    } else {
      const municipality = listMetros.find((municipality) => municipality?.cat_b === e);
      currentMetro = municipality;
      setSelectedMetro(municipality);
    }

    const spatialList = get_spatial_list_from_muncipality(currentMetro);
    const temporalList = get_temporal_list(selectedOutput, spatialList[0]);
    const viewasList = get_viewas_list(selectedOutput, spatialList[0], temporalList[0], selectedAggregate);

    setFiltersApplied(false);
    setlistSpatial(spatialList);
    setSelectedSpatial(spatialList[0]);
    setSelectedOutput("");
    setlistViewas(viewasList);
    setSelectedViewAs(viewasList[0]);
    setSecondSelectedMonth();
    setSecondSelectedYear();
    setOutputSelected(false);
    setAggregateVariablesSelected(false);
    setViewAsSelected(false);
    setYearSelected(false);
    setMonthSelected(false);
    setSecondYearSelected(false);
    setSecondMonthSelected(false);
  }
  function handleOutputSelect(e) {
    setOutputSelected(true);
    const outputSel = e.target.value;
    const spatialList = get_spatial_list_from_muncipality(selectedMetro);
    const temporalList = get_temporal_list(outputSel, spatialList[0]);
    const aggregateList = get_aggregate_list(outputSel, spatialList[0], temporalList[0]);
    const viewasList = get_viewas_list(outputSel, spatialList[0], temporalList[0], "");
    setFiltersApplied(false);
    setSelectedOutput(outputSel);
    setlistSpatial(spatialList[0]);
    setlistTemporal(temporalList);
    setlistAggregate(aggregateList);
    setlistViewas(viewasList);
    setSelectedSpatial(spatialList[0]);
    setSelectedTemporal(temporalList[0]);
    setSelectedAggregate("unselected");
    setSelectedViewAs("");
    clearAdditionalFilters();
    setAggregateVariablesSelected(false);
    setViewAsSelected(false);
    setSexSelected(false);
    setYouthAltSelected(false); // Drop Down Menu version of Youth Selection
    if (outputSel === "Gini Coefficient") {
      setSelectedAggregate("");
    }
  }
  function handleSpatialSelect(e) {
    const spatialSel = e.target.value;
    const temporalList = get_temporal_list(selectedOutput, spatialSel);
    const aggregateList = get_aggregate_list(selectedOutput, spatialSel, temporalList[0]);
    const viewasList = get_viewas_list(selectedOutput, spatialSel, temporalList[0], aggregateList[0]);
    setSelectedSpatial(spatialSel);
    setlistTemporal(temporalList);
    setlistAggregate(aggregateList);
    setlistViewas(viewasList);
    setSelectedTemporal(temporalList[0]);
    setSelectedAggregate(aggregateList[0]);
    setSelectedViewAs(viewasList[0]);
  }
  function handleTemporalSelect(e) {
    const temporalSel = e.target.value;
    const aggregateList = get_aggregate_list(selectedOutput, selectedSpatial, temporalSel);
    const viewasList = get_viewas_list(selectedOutput, selectedSpatial, temporalSel, aggregateList[0]);
    setSelectedTemporal(temporalSel);
    setlistAggregate(aggregateList);
    setlistViewas(viewasList);
    setSelectedViewAs("");
    setSecondSelectedMonth();
    setSecondSelectedYear();
    setFiltersApplied(false);
    setViewAsSelected(false);
  }
  function handleYearSelect(e) {
    setYearSelected(true);
    const yearSel = e.target.value;
    setSelectedYear(yearSel);
    setFiltersApplied(false);
  }
  function handleSecondYearSelect(e) {
    setSecondYearSelected(true);
    const yearSel = e.target.value;
    setSecondSelectedYear(yearSel);
    setFiltersApplied(false);
  }
  function handleMonthSelect(e) {
    setMonthSelected(true);
    const yearSel = e.target.value;
    setSelectedMonth(yearSel);
    setFiltersApplied(false);
  }
  function handleSecondMonthSelect(e) {
    setSecondMonthSelected(true);
    const yearSel = e.target.value;
    setSecondSelectedMonth(yearSel);
    setFiltersApplied(false);
  }
  function handleAggregateSelect(e) {
    setAggregateVariablesSelected(true);
    const aggregateSel = e.target.value;
    const viewasList = get_viewas_list(selectedOutput, selectedSpatial, selectedTemporal, aggregateSel);
    setFiltersApplied(false);
    setlistViewas(viewasList);
    setSelectedViewAs("");
    setSelectedAggregate(aggregateSel);
    clearIndustryList();
    clearSecondYear();
    clearSecondMonth();
    setViewAsSelected(false);
    if (aggregateSel?.includes("Industry") && listIndustries.length === 0) {
      getIndustryList(1);
    }
  }
  function handleSexSelect(e) {
    setSexSelected(true);
    setSelectedSex(e.target.value);
    setFiltersApplied(false);
  }
  function handleYouthAltSelect(e){
    setYouthAltSelected(true);
    setSelectedYouthAlt(e.target.value);
    setFiltersApplied(false);
  }
  function handleFirmSizeSARSSelect(e) {
    setSelectedFirmSizeSARS(e.target.value);
    setFiltersApplied(false);
  }
  function handleMultiEstSelect(e) {
    setSelectedMultiEst(e.target.value);
    setFiltersApplied(false);
  }
  function handleImmigrationSelect(e) {
    setSelectedImmigration(e.target.value);
    setFiltersApplied(false);
  }
  function handleExportStatusSelect(e) {
    setSelectedExportStatus(e.target.value);
    setFiltersApplied(false);
  }
  function handleImportStatusSelect(e) {
    setSelectedImportStatus(e.target.value);
    setFiltersApplied(false);
  }
  function handleViewasSelect(e) {
    setViewAsSelected(true);
    const viewasSel = e.target.value;
    setSelectedViewAs(viewasSel);
    if (viewasSel?.includes("Change") && selectedYear === listYears[listYears.length - 1]) {
      setSelectedYear("");
    }
    if (!viewasSel?.includes("Change") && secondSelectedYear) {
      setSecondSelectedYear("");
      setSecondSelectedMonth("");
    }
    setFiltersApplied(false);
    setSecondYearSelected(false);
    setSecondMonthSelected(false);
  }
  function handleIndustrySelection(e, industryLevel) {
    const selection = e.target.value;
    if (industryLevel < highestIndustryLevelAvailable) {
      getIndustryList(industryLevel + 1, selection);
    } else {
      // Don't get another level
    }
    let industrySelections = selectedIndustry;
    industrySelections.length = industryLevel - 1;
    industrySelections.push(selection);
    setSelectedIndustry(industrySelections);
    setSelectedIndustryLevel(selection === "" ? industryLevel - 1 : industryLevel);
    setFiltersApplied(false);
  }
  function reDrawMap(e) {
    setDisplayedViewAs(selectedViewAs);
    var url, vfield, vget_field, vpoly_field, poly_data, vkey, params;
    vfield = "";
    url = "/api/map-explorer/";
    params = { taxdata_modelname: applicableTaxdataModelName, year: selectedYear };
    if (selectedViewAs !== "") {
      params.viewas = selectedViewAs;
      if (isDivergingView) {
        params.secondyear = secondSelectedYear;
      }
      if (selectedTemporal?.toLowerCase()?.includes("month")) {
        params.month = selectedMonth;
        if (isDivergingView) {
          params.secondmonth = secondSelectedMonth;
        }
      }
    }
    setFiltersApplied(true);
    setIsLoading(true);
    // get map data
    if (selectedMetro?.cat_b === "All") {
      poly_data = { metrodata };
      vkey = "metrodata";
      vget_field = "cat_b";
      vpoly_field = "CAT_B";
      //TODO: potentially change this to 'Employees'
      if (selectedOutput === "FTE Employees") {
        params.spatial = "metro";
        params.output = "fte";
        //vfield = "fte";
        vfield = "display";
      }
      if (selectedOutput === "Establishments") {
        params.spatial = "metro";
        params.output = "firms";
        //vfield = "firms";
        vfield = "display";
      }
      if (selectedOutput === "Gini Coefficient") {
        params.spatial = "metro";
        params.output = "gini";
        //vfield = "gini";
        vfield = "display";
      }
      if (selectedOutput === "Median Income") {
        params.spatial = "metro";
        params.output = "medianincome";
        //vfield = "medianincome";
        vfield = "display";
      }
      if (vfield === "") {
        swal("No Data available!", "Please select other filters", "error", {
          buttons: false,
        });
        return;
      }
    } else if (selectedMetro?.cat_b === "Province") {
      poly_data = { provinceData };
      vkey = "provinceData";
      vget_field = "place_id";
      vpoly_field = "PROVINCE";
      if (selectedOutput === "FTE Employees") {
        params.spatial = "allprovinces";
        params.output = "fte";
        //vfield = "fte";
        vfield = "display";
      }
      if (selectedOutput === "Establishments") {
        params.spatial = "allprovinces";
        params.output = "firms";
        //vfield = "firms";
        vfield = "display";
      }
      if (selectedOutput === "Gini Coefficient") {
        params.spatial = "allprovinces";
        params.output = "gini";
        //vfield = "gini";
        vfield = "display";
      }
      if (selectedOutput === "Median Income") {
        params.spatial = "allprovinces";
        params.output = "medianincome";
        //vfield = "medianincome";
        vfield = "display";
      }
      if (vfield === "") {
        swal("No Data available!", "Please select other filters", "error", {
          buttons: false,
        });
        return;
      }
    } else if (selectedMetro?.cat_b === "District") {
      poly_data = { districtData };
      vkey = "districtData";
      vget_field = "place_id";
      vpoly_field = "DISTRICT";
      if (selectedOutput === "FTE Employees") {
        params.spatial = "alldistricts";
        params.output = "fte";
        //vfield = "fte";
        vfield = "display";
      }
      if (selectedOutput === "Establishments") {
        params.spatial = "alldistricts";
        params.output = "firms";
        //vfield = "firms";
        vfield = "display";
      }
      if (selectedOutput === "Gini Coefficient") {
        params.spatial = "alldistricts";
        params.output = "gini";
        //vfield = "gini";
        vfield = "display";
      }
      if (selectedOutput === "Median Income") {
        params.spatial = "alldistricts";
        params.output = "medianincome";
        //vfield = "medianincome";
        vfield = "display";
      }
      if (vfield === "") {
        swal("No Data available!", "Please select other filters", "error", {
          buttons: false,
        });
        return;
      }
    } else {
      // display hex data
      poly_data = { hexdata };
      vkey = "hexdata";
      vfield = "";
      vget_field = "hex7";
      vpoly_field = "hex7";
      params.cat_b = selectedMetro?.cat_b;
      //TODO: potentially change this to 'Employees'
      if (selectedOutput === "FTE Employees") {
        params.spatial = "hex7";
        params.output = "fte";
        //vfield = "fte";
        vfield = "display";
      }
      if (selectedOutput === "Establishments") {
        params.spatial = "hex7";
        params.output = "firms";
        //vfield = "firms";
        vfield = "display";
      }
      if (selectedOutput === "Gini Coefficient") {
        params.spatial = "hex7";
        params.output = "gini";
        //vfield = "gini";
        vfield = "display";
      }
      if (selectedOutput === "Median Income") {
        params.spatial = "hex7";
        params.output = "medianincome";
        //vfield = "medianincome";
        vfield = "display";
      }
      if (vfield === "") {
        swal("No Data available!", "Please select other filters", "error", {
          buttons: false,
        });
        return;
      }
    }

    if (selectedTemporal !== "") {
      params.temporal = selectedTemporal?.toLowerCase()?.includes("year") ? "year" : "month";
    }

    if (selectedAggregate !== "unselected" || selectedAggregate !== "") {
      if (isIncludedInAggregate("Industry")) {
        params.sic_code = selectedIndustry[selectedIndustryLevel - 1];
        params.sic_level = selectedIndustryLevel;
      }

      if (isIncludedInAggregate("Wage Band")) {
        params.wagelower = wageBandOptions[selectedWageBand[0]];
        params.wageupper = wageBandOptions[selectedWageBand[1]];
      }

      if (isIncludedInAggregate("Age Group")) {
        params.agelower = ageGroupOptions[selectedAgeGroup[0]];
        params.ageupper = ageGroupOptions[selectedAgeGroup[1]];
      }

      {/* Youth - District and Province Median Income : Drop Down */}
      if ((isIncludedInAggregate("Youth") && selectedSpatial === "Province" && selectedOutput === "Median Income") || (isIncludedInAggregate("Youth") && selectedSpatial === "District" && selectedOutput === "Median Income")) {
        // const youthAltLower = youthAltOptions.split(",")[0]
        // const youthAltUpper = youthAltOptions.split(",")[1]
        params.youthlower = selectedYouthAlt.split(",")[0]
        params.youthupper = selectedYouthAlt.split(",")[1]        
        // console.log("Drop Down params.youthlower: " + params.youthlower)
        // console.log("Drop Down params.youthupper: " + params.youthupper)
      }

      {/* Youth - Not District and Province Median Income i.e. all others : Slider */}
      if ((isIncludedInAggregate("Youth") && selectedSpatial !== "Province" && selectedOutput !== "Median Income") || (isIncludedInAggregate("Youth") && selectedSpatial !== "District" && selectedOutput !== "Median Income") || (isIncludedInAggregate("Youth") && (selectedSpatial === "Municipality" || selectedSpatial === "Hexagon")) ) {
        params.youthlower = youthOptions[selectedYouth[0]];
        params.youthupper = youthOptions[selectedYouth[1]];
        // console.log("Slider params.youthlower: " + params.youthlower)
        // console.log("Slider params.youthupper: " + params.youthupper)
      }

      if (isIncludedInAggregate("Gender") || isIncludedInAggregate("Sex")) {
        // Leave config option with Gender, just in case.
        params.gender = selectedSex; // Leave parameter as gender to avoid much BE changes.
      }

      if (isIncludedInAggregate("Establishment Size (SARS)")) {
        params.firmsize_sars = selectedFirmSizeSARS;
      }

      if (isIncludedInAggregate("Multi-Establishment")) {
        params.multiest = selectedMultiEst;
      }

      if (isIncludedInAggregate("Immigration")) {
        params.immigration = selectedImmigration;
      }

      if (isIncludedInAggregate("Establishment Size (Number of Employees)")) {
        params.firmsize_fte_lower = firmSizeFTEOptions[selectedFirmSizeFTE[0]];
        params.firmsize_fte_upper = firmSizeFTEOptions[selectedFirmSizeFTE[1]];
      }

      if (isIncludedInAggregate("Export Status")) {
        params.export = selectedExportStatus;
      }

      if (isIncludedInAggregate("Import Status")) {
        params.import = selectedImportStatus;
      }

      if (isIncludedInAggregate("Opened Establishments")) {
        params.openclosed = "open";
        vfield = "display";
      }

      if (isIncludedInAggregate("Closed Establishments")) {
        params.openclosed = "closed";
        vfield = "display";
      }

      if (isIncludedInAggregate("Establishment Size (FTE)")) {
        params.establishmentsize_fte_lower = establishmentSizeFTEOptions[selectedFirmSizeFTE[0]];
        params.establishmentsize_fte_upper = establishmentSizeFTEOptions[selectedFirmSizeFTE[1]];
      }

      if (isIncludedInAggregate("Firm Size (FTE)")) {
        params.firmsize_fte_lower = firmSizeFTEOptions[selectedFirmSizeFTE[0]];
        params.firmsize_fte_upper = firmSizeFTEOptions[selectedFirmSizeFTE[1]];
      }

      if (isIncludedInAggregate("Firm Size (SARS)")) {
        params.firmsize_sars = selectedFirmSizeSARS;
      }
    }

    // get data
    var vmax = 0;
    var vmin = Number.MAX_SAFE_INTEGER;

    var vpoly = cloneDeep(poly_data); // Colour Layer
    var vpoly_grey = cloneDeep(poly_data); // Grey Layer

    vpoly[vkey].features = []; // Colour Layer
    vpoly_grey[vkey].features = []; // Grey Layer
    axiosNoAuthInstance
      .get(url, { params: params })
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          swal("No Data available!", "Please select other filters", "error", {
            buttons: false,
          });
          setIsLoading(false);
          return;
        }

        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < poly_data[vkey].features.length; j++) {
            if (poly_data[vkey].features[j].properties[vpoly_field] === response.data[i][vget_field]) {
              // Start dual layers here 1. Grey Error Layer 2. Colour Layer (Default)
              if (typeof response.data[i][vfield] != "number") {
                let vfeature_grey = poly_data[vkey].features[j]; // Grey Layer
                // Text >>> goes to new Grey hex layer, 3 tested approaches below work
                vfeature_grey.properties.value = "0";
                vfeature_grey.properties.label = [selectedOutput];
                vfeature_grey.properties.label.push(response.data[i][vfield]);
                vpoly_grey[vkey]["features"].push(vfeature_grey); // Grey Layer
              } else {
                let vfeature = poly_data[vkey].features[j]; // Colour Layer
                vfeature.properties.value = response.data[i][vfield];
                if (parseFloat(vfeature.properties.value) > parseFloat(vmax)) {
                  vmax = parseFloat(vfeature.properties.value);
                }
                if (parseFloat(vfeature.properties.value) < parseFloat(vmin)) {
                  vmin = parseFloat(vfeature.properties.value);
                }
                // ToolTip Map Labels
                if (selectedMetro?.cat_b === "All") {
                  vfeature.properties.label = [[vfeature.properties["MUNICNAME"]] + " (" + [vfeature.properties["CAT_B"]] + ")"];
                  vfeature.properties.label.push(selectedOutput);
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));
                } else if (selectedMetro?.cat_b === "Province") {
                  vfeature.properties.label = [[response.data[i]["place_name"]] + " (" + [vfeature.properties["PROVINCE"]] + ")"];
                  vfeature.properties.label.push(selectedOutput);
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));
                } else if (selectedMetro?.cat_b === "District") {
                  vfeature.properties.label = [[vfeature.properties["DISTRICT_N"]] + " (" + [vfeature.properties["DISTRICT"]] + ")"];
                  vfeature.properties.label.push(selectedOutput);
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));
                } else {
                  vfeature.properties.label = [selectedOutput];
                  vfeature.properties.label.push(parseFloat(response.data[i][vfield].toFixed(4)));
                }
                vpoly[vkey]["features"].push(vfeature); // Colour Layer
              }
              continue;
            }
          }
        }
        setmapData(vpoly[vkey]); // Colour Layer
        setmapDataGrey(vpoly_grey[vkey]); // Grey Layer
        setDisplayedMetro(selectedMetro?.municname);
        setDisplayedOutput(selectedOutput);
        setmaxValue(vmax);
        setMinValue(vmin === Number.MAX_SAFE_INTEGER ? 0 : vmin);
        setreloadReport(reloadReport + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 404) {
          setIsLoading(false);
          swal("Failed", `${error?.response?.data?.detail}`, "warning", {
            timer: 10000,
            buttons: false,
          });
        } else if (error?.response == null) {
          setIsLoading(false);
          swal("No Data available!", "Please select other filters", "error", {
            timer: 10000,
            buttons: false,
          });
        } else {
          setIsLoading(false);
          swal("Failed", `${error}`, "error", {
            timer: 4000,
            buttons: false,
          });
        }
      });
  }

  const canDisplayVisual = () => {
    if (!selectedOutput || !selectedMetro?.cat_b || !selectedYear || (selectedAggregate?.toLowerCase()?.includes("sex") && !selectedSex) || !selectedViewAs || (!selectedOutput?.toLowerCase()?.includes("gini") && selectedAggregate === "unselected") || (isDivergingView && !secondSelectedYear) || (selectedTemporal?.toLowerCase()?.includes("month") && !selectedMonth) || (selectedTemporal?.toLowerCase()?.includes("month") && isDivergingView && !secondSelectedMonth)) {
      return false;
    }

    if (isIncludedInAggregate("Industry") && !filtersApplied) {
      return selectedIndustryLevel > 0;
    }
    return !filtersApplied;
  };

  const selectedIndustryText = () => {
    if (selectedIndustryLevel <= 0) {
      return "Required";
    }

    let selectedIndustryActivity = listIndustries[selectedIndustryLevel - 1].filter((x) => x.code.toString() === selectedIndustry[selectedIndustryLevel - 1]);

    if (selectedIndustryActivity) {
      return selectedIndustry[selectedIndustryLevel - 1] + ":" + selectedIndustryActivity[0]?.activity.substring(0, 19).concat(selectedIndustryActivity[0]?.activity.length > 19 ? "..." : "");
    }

    return "";
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const isFirstYearOptionDisabled = (year) => {
    return (secondSelectedYear && year >= secondSelectedYear) || (isDivergingView && year === listYears[listYears.length - 1]);
  };

  const isSecondYearOptionDisabled = (year) => {
    return (selectedYear && year <= selectedYear) || (isDivergingView && year === listYears[0]);
  };

  const isIncludedInAggregate = (text) => {
    return selectedAggregate?.toLowerCase()?.includes(text?.toLowerCase());
  };

  return (
    <div className="overflow-hidden">
      <Modal className="modal" open={isIndustryModalOpen} onClose={() => setIsIndustryModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <div id="modal-modal-title">Select Industry To Your Desired Level:</div>
          {listIndustries[0] && highestIndustryLevelAvailable >= 0 && (
            <div>
              <label>Level 1: </label>
              <select className="form-control placeholder-black bg-gray-200" name="filterIndustry1" value={selectedIndustry[0] || ""} onChange={(e) => handleIndustrySelection(e, 1)}>
                {listIndustries[0]?.map((e) => {
                  return (
                    <option key={e.code} value={e.code}>
                      {e.activity === "None" ? e.activity : e.code + ":" + e.activity}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {listIndustries[1] && highestIndustryLevelAvailable >= 1 && (
            <div>
              <label>Level 2: </label>
              <select className="form-control placeholder-black bg-gray-200" name="filterIndustry2" value={selectedIndustry[1] || ""} onChange={(e) => handleIndustrySelection(e, 2)}>
                {listIndustries[1]?.map((e) => {
                  return (
                    <option key={e.code} value={e.code}>
                      {e.activity === "None" ? e.activity : e.code + ":" + e.activity}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {listIndustries[2] && highestIndustryLevelAvailable >= 2 && (
            <div>
              <label>Level 3: </label>
              <select className="form-control placeholder-black bg-gray-200" name="filterIndustry3" value={selectedIndustry[2] || ""} onChange={(e) => handleIndustrySelection(e, 3)}>
                {listIndustries[2]?.map((e) => {
                  return (
                    <option key={e.code} value={e.code}>
                      {e.activity === "None" ? e.activity : e.code + ":" + e.activity}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {listIndustries[3] && highestIndustryLevelAvailable >= 3 && (
            <div>
              <label>Level 4: </label>
              <select className="form-control placeholder-black bg-gray-200" name="filterIndustry4" value={selectedIndustry[3] || ""} onChange={(e) => handleIndustrySelection(e, 4)}>
                {listIndustries[3]?.map((e) => {
                  return (
                    <option key={e.code} value={e.code}>
                      {e.activity === "None" ? e.activity : e.code + ":" + e.activity}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {listIndustries[4] && highestIndustryLevelAvailable >= 4 && (
            <div>
              <label>Level 5: </label>
              <select className="form-control placeholder-black bg-gray-200" name="filterIndustry5" value={selectedIndustry[4] || ""} onChange={(e) => handleIndustrySelection(e, 5)}>
                {listIndustries[4]?.map((e) => {
                  return (
                    <option key={e.code} value={e.code}>
                      {e.activity === "None" ? e.activity : e.code + ":" + e.activity}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <button className="btn btn-secondary w-full" onClick={() => setIsIndustryModalOpen(false)}>
            Confirm
          </button>
        </Box>
      </Modal>
      {showSidebarLeft ? (
        <div className="fixed bg-gray-200 cursor-pointer ease-in-out duration-300 z-20" onClick={() => setShowSidebarLeft(!showSidebarLeft)}>
          <div className="flex content-center">
            <div className="w-[320px] pl-6 py-3 text-ccre-green-900 text-base">Map Explorer</div>
            <div className="border-r-4 border-b-4 border-black w-[10px] h-[10px] rotate-[225deg] absolute top-5 right-4">&nbsp;</div>
          </div>
        </div>
      ) : (
        <div className="fixed bg-gray-200 cursor-pointer ease-in-out duration-300 z-20" onClick={() => setShowSidebarLeft(!showSidebarLeft)}>
          <div className="flex content-center">
            <div className="w-[320px] pl-6 py-3 text-ccre-green-900 text-base">Map Explorer</div>
            <div className="border-r-4 border-b-4 border-black w-[10px] h-[10px] rotate-[45deg] absolute top-4 right-4">&nbsp;</div>
          </div>
        </div>
      )}
      <div className={`sidebar-left top-[110px] left-[0px] w-[320px] bg-gray-200 text-black fixed z-20 ease-in-out duration-300 overflow-hidden  ${showSidebarLeft ? "h-full" : "h-0"}`}>
        <aside className="w-full px-2">
          <div className="sticky top-0 p-4" style={{ overflowY: "auto", maxHeight: 620, overflowX: "hidden" }}>
            <div className="form-group">
              <select required className={`form-control hsrc-form-select !text-sm ${areaSelected ? "" : "!bg-white"}`} style={{ backgroundColor: areaSelected ? "#ecf7f0" : "" }} name="filterMetro" value={selectedMetro?.cat_b} onChange={(e) => handleMetroSelect(e.target.value)}>
                <option value="" hidden>
                  Select Area
                </option>
                <option value="spatial-type" disabled className="font-bold text-lg text-black !bg-white">
                  National ...
                </option>
                <option value="Province" className="!bg-white">
                  Provinces
                </option>
                <option value="District" className="!bg-white">
                  District municipalities (incl metros)
                </option>
                <option value="All" className="!bg-white">
                  Local municipalities (incl metros)
                </option>
                <option value="spatial-type" disabled className="font-bold text-lg text-black !bg-white">
                  Metros ...
                </option>
                {listMetros?.map((e) => {
                  return (
                    <option key={e?.cat_b} value={e?.cat_b} className="!bg-white">
                      {e.municname}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mt-2">
              <select required className={`form-control hsrc-form-select !text-sm ${outputSelected ? "" : "!bg-white"}`} style={{ backgroundColor: outputSelected ? "#ecf7f0" : "" }} name="filterOutput" value={selectedOutput} onChange={(e) => handleOutputSelect(e)}>
                <option value="" hidden className="!bg-white">
                  Select Output
                </option>
                {listOutput.map((e, key) => {
                  return (
                    <option key={e} value={e} className="!bg-white">
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
            {false && (
              <div className="form-group mt-2">
                <select disabled="disabled" className="form-control hsrc-form-select !text-sm" name="filterSpatial" value={selectedSpatial} onChange={(e) => handleSpatialSelect(e)}>
                  <option value="" hidden>
                    Spatial
                  </option>
                  {listSpatial.map((e, key) => {
                    return (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {selectedOutput?.toLowerCase()?.includes("fte") && (
              <div className="form-group mt-2">
                <select className="form-control hsrc-form-select !text-sm " style={{ backgroundColor: "#ecf7f0" }} name="filterTemporal" value={selectedTemporal} onChange={(e) => handleTemporalSelect(e)}>
                  <option value="" hidden className="!bg-white">
                    Temporal
                  </option>
                  {listTemporal.map((e, key) => {
                    return (
                      <option className="!bg-white" key={e} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="form-group mt-2">
              <select className={`form-control hsrc-form-select !text-sm ${aggregateVariablesSelected ? "" : "!bg-white"}`} style={{ backgroundColor: aggregateVariablesSelected ? "#ecf7f0" : "" }} name="filterAggregate" value={selectedAggregate} onChange={(e) => handleAggregateSelect(e)} disabled={!selectedMetro || !selectedOutput || !listAggregate?.length > 0}>
                <option value="unselected" hidden className="!bg-white">
                  Select Aggregate Variables
                </option>
                <option value="" className="!bg-white">
                  None
                </option>
                {listAggregate.map((e, key) => {
                  return (
                    <option className="!bg-white" key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mt-2">
              {isIncludedInAggregate("Industry") && (
                <div className="mt-2 text-sm">
                  <label>Industry: </label>
                  <button className="btn py-1 text-sm text-ccre-orange-900 hover:underline" onClick={() => setIsIndustryModalOpen(true)}>
                    <span>{selectedIndustryText() === "Required" ? "Please select... (Required)" : "[" + selectedIndustryText() + "]"}</span>
                  </button>
                </div>
              )}
              {isIncludedInAggregate("Age Group") && (
                <div className="mt-2 text-sm">
                  <label>Age Group: </label>
                  <div className="px-3 mt-9">
                    <Slider sx={{ width: "90%" }} value={selectedAgeGroup} onChange={handleAgeChange} min={0} step={1} max={ageGroupOptions.length - 1} valueLabelDisplay="on" valueLabelFormat={(value) => ageGroupOptions[value]} />
                  </div>
                </div>
              )}
              {/* Youth - District and Province Median Income : Drop Down (Single Select) */}
              {((isIncludedInAggregate("Youth") && selectedSpatial === "Province" && selectedOutput === "Median Income") || (isIncludedInAggregate("Youth") && selectedSpatial === "District" && selectedOutput === "Median Income")) && (
                <div className="mt-2 text-sm">
                  <label>Youth: </label>
                  <select className={`hsrc-form-select ${youthAltSelected ? "" : "!bg-white"}`} style={{ backgroundColor: youthAltSelected ? "#ecf7f0" : "" }} name="filterYouth" value={selectedYouthAlt} onChange={(e) => handleYouthAltSelect(e)}>
                    <option value="" hidden className="!bg-white">
                      Select...
                    </option>
                    {youthAltOptions.map((e) => {
                      return (
                        <option className="!bg-white" key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}                        
              {/* Youth - Not District and Province Median Income i.e. all others : Slider (MultiSelect) */}
              {((isIncludedInAggregate("Youth") && selectedSpatial !== "Province" && selectedOutput !== "Median Income") || (isIncludedInAggregate("Youth") && selectedSpatial !== "District" && selectedOutput !== "Median Income") || (isIncludedInAggregate("Youth") && (selectedSpatial === "Municipality" || selectedSpatial === "Hexagon")) ) && ( 
                <div className="text-sm">
                  <label>Youth: </label>
                  <div className="px-3 mt-9">
                    <Slider sx={{ width: "90%" }} value={selectedYouth} onChange={handleYouthChange} min={0} step={1} max={youthOptions.length - 1} valueLabelDisplay="on" valueLabelFormat={(value) => youthOptions[value]} />
                  </div>
                </div>
              )}
              {(isIncludedInAggregate("Gender") || isIncludedInAggregate("Sex")) && ( // Leave config option with Gender, just in case.
                <div className="mt-2 text-sm">
                  <label>Sex: </label>
                  <select className={`hsrc-form-select ${sexSelected ? "" : "!bg-white"}`} style={{ backgroundColor: sexSelected ? "#ecf7f0" : "" }} name="filterSex" value={selectedSex} onChange={(e) => handleSexSelect(e)}>
                    <option value="" hidden className="!bg-white">
                      Select...
                    </option>
                    {sexOptions.map((e) => {
                      return (
                        <option className="!bg-white" key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Export") && (
                <div className="mt-2 text-sm">
                  <label>Export Status: </label>
                  <select className="hsrc-form-select !text-sm" style={{ backgroundColor: "#ecf7f0" }} name="filterExport" value={selectedExportStatus} onChange={(e) => handleExportStatusSelect(e)}>
                    {exportStatusOptions.map((e) => {
                      return (
                        <option key={e.value} className="!bg-white" value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Import") && (
                <div className="mt-2 text-sm">
                  <label>Import Status: </label>
                  <select className="hsrc-form-select !text-sm" style={{ backgroundColor: "#ecf7f0" }} name="filterImport" value={selectedImportStatus} onChange={(e) => handleImportStatusSelect(e)}>
                    {importStatusOptions.map((e) => {
                      return (
                        <option key={e.value} className="!bg-white" value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Establishment Size") && isIncludedInAggregate("SARS") && (
                <div className="mt-2 text-sm">
                  <label>Establishment Size (SARS): </label>
                  <select className="hsrc-form-select !text-sm" name="filterFirmSars" value={selectedFirmSizeSARS} onChange={(e) => handleFirmSizeSARSSelect(e)}>
                    {firmSizeSARSOptions.map((e) => {
                      return (
                        <option key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Firm Size") && isIncludedInAggregate("SARS") && (
                <div className="mt-2 text-sm">
                  <label>Firm Size (SARS): </label>
                  <select className="hsrc-form-select !text-sm" style={{ backgroundColor: "#ecf7f0" }} name="filterFirmSars" value={selectedFirmSizeSARS} onChange={(e) => handleFirmSizeSARSSelect(e)}>
                    {firmSizeSARSOptions.map((e) => {
                      return (
                        <option className="!bg-white" key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Establishment Size") && isIncludedInAggregate("FTE") && (
                <div className="mt-2 text-sm">
                  <label>Establishment Size (Number of Employees): </label>
                  <div className="px-3 mt-9">
                    <Slider sx={{ width: "90%" }} value={selectedFirmSizeFTE} onChange={handleFirmSizeFTEChange} min={0} step={1} max={establishmentSizeFTEOptions.length - 1} valueLabelDisplay="on" valueLabelFormat={(value) => establishmentSizeFTEOptions[value].toLocaleString()} />
                  </div>
                </div>
              )}
              {isIncludedInAggregate("Firm Size") && isIncludedInAggregate("FTE") && (
                <div className="mt-2 text-sm">
                  <label>Firm Size (Number of Employees): </label>
                  <div className="px-3 mt-9">
                    <Slider sx={{ width: "90%" }} value={selectedFirmSizeFTE} onChange={handleFirmSizeFTEChange} min={0} step={1} max={firmSizeFTEOptions.length - 1} valueLabelDisplay="on" valueLabelFormat={(value) => firmSizeFTEOptions[value].toLocaleString()} />
                  </div>
                </div>
              )}
              {isIncludedInAggregate("Multi") && isIncludedInAggregate("Establishment") && (
                <div className="mt-2 text-sm">
                  <label>Multi Establishment: </label>
                  <select className="hsrc-form-select !text-sm" name="filterMultiEst" value={selectedMultiEst} onChange={(e) => handleMultiEstSelect(e)}>
                    {multiEstablishmentOptions.map((e) => {
                      return (
                        <option key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Immigration") && (
                <div className="mt-2 text-sm">
                  <label>Immigration: </label>
                  <select className="hsrc-form-select !text-sm" style={{ backgroundColor: "#ecf7f0" }} name="filterImmigration" value={selectedImmigration} onChange={(e) => handleImmigrationSelect(e)}>
                    {immigrationOptions.map((e) => {
                      return (
                        <option className="!bg-white" key={e.value} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {isIncludedInAggregate("Wage Band") && (
                <div className="mt-2 text-sm">
                  <label>Wage Band: </label>
                  <div className="px-3 mt-9">
                    <Slider sx={{ width: "90%" }} value={selectedWageBand} onChange={handleWageBandChange} min={0} step={1} max={wageBandOptions.length - 1} valueLabelDisplay="on" valueLabelFormat={(value) => wageBandOptions[value].toLocaleString()} />
                  </div>
                </div>
              )}
            </div>
            <div className="form-group mt-2">
              <select className={`form-control hsrc-form-select !text-sm ${viewAsSelected ? "" : "!bg-white"}`} style={{ backgroundColor: viewAsSelected ? "#ecf7f0" : "" }} name="filterViewas" value={selectedViewAs} onChange={(e) => handleViewasSelect(e)} disabled={!selectedMetro || !selectedOutput || !listViewas?.length > 0}>
                <option value="" hidden className="!bg-white">
                  Select View
                </option>
                {listViewas.map((e, key) => {
                  return (
                    <option key={e} value={e} className="!bg-white">
                      {e}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex justify-between">
              <div className="form-group mt-2 w-1/2 pr-2">
                <select required className={`form-control hsrc-form-select !text-sm ${yearSelected ? "" : "!bg-white"}`} style={{ backgroundColor: yearSelected ? "#ecf7f0" : "" }} name="filterYear" value={selectedYear} onChange={(e) => handleYearSelect(e)} disabled={!selectedMetro || !selectedOutput}>
                  <option value="" hidden className="!bg-white">
                    Select Year
                  </option>
                  {listYears.map((e, key) => {
                    return (
                      <option key={e} value={e} disabled={isFirstYearOptionDisabled(e)} className="!bg-white">
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
              {selectedTemporal.toLowerCase().includes("month") && (
                <div className="form-group mt-2 w-1/2">
                  <select required className={`form-control hsrc-form-select !text-sm ${monthSelected ? "" : "!bg-white"}`} style={{ backgroundColor: monthSelected ? "#ecf7f0" : "" }} name="filterMonth" value={selectedMonth} onChange={(e) => handleMonthSelect(e)}>
                    <option value="" hidden className="!bg-white">
                      Select Month
                    </option>
                    {monthOptions.map((e, key) => {
                      return (
                        <option key={e.value} value={e.value} className="!bg-white">
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="flex justify-between">
              {isDivergingView && (
                <div className="form-group mt-2 w-1/2 pr-2">
                  <select required className={`form-control hsrc-form-select !text-sm ${secondYearSelected ? "" : "!bg-white"}`} style={{ backgroundColor: secondYearSelected ? "#ecf7f0" : "" }} name="filterSecondYear" value={secondSelectedYear} onChange={(e) => handleSecondYearSelect(e)}>
                    <option value="" hidden className="!bg-white">
                      Second Year
                    </option>
                    {listYears.map((e, key) => {
                      return (
                        <option key={e} value={e} disabled={isSecondYearOptionDisabled(e)} className="!bg-white">
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              {selectedTemporal.toLowerCase().includes("month") && isDivergingView && (
                <div className="form-group mt-2 w-1/2">
                  <select required className={`form-control hsrc-form-select !text-sm ${secondMonthSelected ? "" : "!bg-white"}`} style={{ backgroundColor: secondMonthSelected ? "#ecf7f0" : "" }} name="filterSecondMonth" value={secondSelectedMonth} onChange={(e) => handleSecondMonthSelect(e)}>
                    <option value="" hidden className="!bg-white">
                      Second Month
                    </option>
                    {monthOptions.map((e, key) => {
                      return (
                        <option key={e.value} value={e.value} disabled={isSecondYearOptionDisabled(e)} className="!bg-white">
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <button className={`btn ${canDisplayVisual() ? "btn-secondary" : "btn-disabled"} w-full mt-3 mb-10 !text-sm`} disabled={!canDisplayVisual()} onClick={reDrawMap}>
              {isLoading ? "Displaying..." : filtersApplied ? "Displayed" : "Display Visual"}
            </button>
          </div>
        </aside>
        <div className="logos absolute bottom-[110px] w-[320px]">
          <div className="grid px-6 py-6 bg-gray-300">
            <div className="flex gap-4">
              <img className="w-20 inline-block" src={hsrclogo} alt="HSRC Logo" />
              <img className="w-20 inline-block" src={citieschairlogo} alt="Cities Chair Logo" />
              <img className="w-20 inline-block" src={csplogo} alt="CSP Logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center relative overflow-hidden">
        <div className="flex flex-row flex-wrap">
          <main role="main" className="w-full h-full">
            {
              <DisplayMap
                key={reloadReport}
                data={mapData}
                data_grey={mapDataGrey}
                metro={displayedMetro}
                output={displayedOutput}
                viewAs={displayedViewAs}
                maxValue={parseFloat(maxValue)}
                minValue={parseFloat(minValue)}
                isLoading={isLoading}
                opacityLevel={opacityLevel}
                colourToggle={colourToggle}
                extrudeToggle={extrudeToggle}
                displayConfig={displayConfig}
                setOpacityLevel={setOpacityLevel}
                setColourToggle={setColourToggle}
                setExtrudeToggle={setExtrudeToggle}
                setDisplayConfig={setDisplayConfig}
              />
            }
          </main>
        </div>
      </div>
    </div>
  );
}
export default TaxDataFiltersForm;
