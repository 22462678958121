import { useState, useEffect, useMemo } from "react";
import swal from "sweetalert";
import saveAs from "file-saver";
import { Tooltip } from "@mui/material";
import axiosNoAuthInstance from "../axiosApiNoAuth";
import DownloadFile from "./DownloadFile";
import { TableDisplay } from "../TableDisplay/TableDisplay";
import { routeConstants } from "../../shared/constants";
import axiosInstance from "../axiosApi";
import helpQuestion from "../../images/icons/help-question.svg";
let filter_menu = [];

function DownloadFiltersForm() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [selectionsSearched, setSelectionsSearched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const [isDownloadingAllFiles, setIsDownloadingAllFiles] = useState(false);
	// Data Files
	const [isDownloadingHexagonalData, setIsDownloadingHexagonalData] = useState(false);
	const [isDownloadingLocalMunicipalData, setIsDownloadingLocalMunicipalData] = useState(false);
	const [isDownloadingDistrictMunicipalData, setIsDownloadingDistrictMunicipalData] = useState(false);
	const [isDownloadingProvinceData, setIsDownloadingProvinceData] = useState(false);
	const [isDownloadingNationalData, setIsDownloadingNationalData] = useState(false);
	// Shape Files
	const [isDownloadingHexagonalShapefiles, setIsDownloadingHexagonalShapefiles] = useState(false);
	const [isDownloadingLocalMunicipalShapefiles, setIsDownloadingLocalMunicipalShapefiles] = useState(false);
	const [isDownloadingDistrictMunicipalShapefiles, setIsDownloadingDistrictMunicipalShapefiles] = useState(false);
	const [isDownloadingProvinceShapefiles, setIsDownloadingProvinceShapefiles] = useState(false);
	// Supplementary Files
	const [isDownloadingSIC7Codes, setIsDownloadingSIC7Codes] = useState(false);
	const [isDownloadingMetadata, setIsDownloadingMetadata] = useState(false);
	const [isDownloadingReleaseNotes, setIsDownloadingReleaseNotes] = useState(false);
	// const [isDownloadingUserGuide, setIsDownloadingUserGuide] = useState(false);
	//
	const [displayedContent, setDisplayedContent] = useState();
	const [fileList, setfileList] = useState([]);
	const [listOutput, setlistOutput] = useState([]);
	const [listSpatial, setlistSpatial] = useState([]);
	const [listTemporal, setlistTemporal] = useState([]);
	const [listAggregate, setlistAggregate] = useState([]);
	//TODO: potentially change this to 'Employees'
	const [selectedOutput, setselectedOutput] = useState("FTE Employees");
	const [selectedSpatial, setselectedSpatial] = useState("Hexagon");
	const [selectedTemporal, setselectedTemporal] = useState("Tax Year");
	const [selectedAggregate, setselectedAggregate] = useState("None");
	// const SupplementaryData = "SupplementaryData";
	const titleTooltip = {
		metadata:
			"The Metadata document allows you to discover the ‘Detailed Descriptions of Datasets and Variables’ used as well as any caveats and limitations",
		spatial: "Variables related to the different spatial components by which the data can be broken down",
		temporal: "Variables related to the different temporal components by which the data can be broken down",
		output:
			"Output variables identify quantifiable values associated with combinations of spatio-temporal components and any other variables that are used for aggregation",
		aggregation:
			"Aggregation variables are supplementary variables by which some of the output variables can be grouped by to better understand specific contexts",
	};

	useEffect(() => {
		if (localStorage.getItem("accessToken") !== null) {
			setIsLoggedIn(true);
		}

		async function getFilterConfig() {
			try {
				setIsLoading(true);
				const response = await axiosNoAuthInstance.get("/api/filterconfig/");
				filter_menu = response.data;
				const initlistOutput = get_output_list();
				const initlistSpatial = get_spatial_list(initlistOutput[0]);
				const initlistTemporal = get_temporal_list(initlistOutput[0], initlistSpatial[0]);
				const initlistAggregate = get_aggregate_list(initlistOutput[0], initlistSpatial[0], initlistTemporal[0]);
				setlistOutput(initlistOutput);
				setlistSpatial(initlistSpatial);
				setlistTemporal(initlistTemporal);
				setlistAggregate(initlistAggregate);
				setIsLoading(false);
			} catch (e) {
				swal("Failed", "error", "error", {
					buttons: false,
					timer: 2000,
				});
				setIsLoading(false);
			}
		}

		getFilterConfig();
	}, []);

	async function downloadHexagonalData() {
		try {
			setIsDownloadingHexagonalData(true);
			const response = await axiosInstance.get(`/download_history/download/Hexagonal_Data.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Hexagonal_Data.7z");
			setIsDownloadingHexagonalData(false);
		} catch (error) {
			setIsDownloadingHexagonalData(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadLocalMunicipalData() {
		try {
			setIsDownloadingLocalMunicipalData(true);
			const response = await axiosInstance.get(`/download_history/download/Local_Municipal_Data.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Local_Municipal_Data.7z");
			setIsDownloadingLocalMunicipalData(false);
		} catch (error) {
			setIsDownloadingLocalMunicipalData(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadDistrictMunicipalData() {
		try {
			setIsDownloadingDistrictMunicipalData(true);
			const response = await axiosInstance.get(`/download_history/download/District_Municipal_Data.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "District_Municipal_Data.7z");
			setIsDownloadingDistrictMunicipalData(false);
		} catch (error) {
			setIsDownloadingDistrictMunicipalData(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadProvinceData() {
		try {
			setIsDownloadingProvinceData(true);
			const response = await axiosInstance.get(`/download_history/download/Province_Data.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "{Province_Data.7z");
			setIsDownloadingProvinceData(false);
		} catch (error) {
			setIsDownloadingProvinceData(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadNationalData() {
		try {
			setIsDownloadingNationalData(true);
			const response = await axiosInstance.get(`/download_history/download/National_Data.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "National_Data.7z");
			setIsDownloadingNationalData(false);
		} catch (error) {
			setIsDownloadingNationalData(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadHexagonalShapefiles() {
		try {
			setIsDownloadingHexagonalShapefiles(true);
			const response = await axiosInstance.get(`/download_history/download/Hexagonal_Shapefiles.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Hexagonal_Shapefiles.7z");
			setIsDownloadingHexagonalShapefiles(false);
		} catch (error) {
			setIsDownloadingHexagonalShapefiles(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadLocalMunicipalShapefiles() {
		try {
			setIsDownloadingLocalMunicipalShapefiles(true);
			const response = await axiosInstance.get(`/download_history/download/Local_Municipal_Shapefiles.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Local_Municipal_Shapefiles.7z");
			setIsDownloadingLocalMunicipalShapefiles(false);
		} catch (error) {
			setIsDownloadingLocalMunicipalShapefiles(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}
	
	async function downloadDistrictMunicipalShapefiles() {
		try {
			setIsDownloadingDistrictMunicipalShapefiles(true);
			const response = await axiosInstance.get(`/download_history/download/District_Municipal_Shapefiles.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "District_Municipal_Shapefiles.7z");
			setIsDownloadingDistrictMunicipalShapefiles(false);
		} catch (error) {
			setIsDownloadingDistrictMunicipalShapefiles(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}
	
	async function downloadProvinceShapefiles() {
		try {
			setIsDownloadingProvinceShapefiles(true);
			const response = await axiosInstance.get(`/download_history/download/Province_Shapefiles.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Province_Shapefiles.7z");
			setIsDownloadingProvinceShapefiles(false);
		} catch (error) {
			setIsDownloadingProvinceShapefiles(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadSIC7Codes() {
		try {
			setIsDownloadingSIC7Codes(true);
			const response = await axiosInstance.get(`/download_history/download/SIC7_Codes.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "SIC7_Codes.7z");
			setIsDownloadingSIC7Codes(false);
		} catch (error) {
			setIsDownloadingSIC7Codes(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadMetadata() {
		try {
			setIsDownloadingMetadata(true);
			const response = await axiosInstance.get(`/download_history/download/Metadata.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Metadata.7z");
			setIsDownloadingMetadata(false);
		} catch (error) {
			setIsDownloadingMetadata(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	async function downloadReleaseNotes() {
		try {
			setIsDownloadingReleaseNotes(true);
			const response = await axiosInstance.get(`/download_history/download/Release_Notes.7z`, {
				timeout: 0,
				responseType: "arraybuffer",
			}); // Added , responseType: 'arraybuffer'
			saveAs(new Blob([response.data]), "Release_Notes.7z");
			setIsDownloadingReleaseNotes(false);
		} catch (error) {
			setIsDownloadingReleaseNotes(false);
			swal("Failed", "error", "error", {
				buttons: false,
				timer: 1000,
			});
		}
	}

	// async function downloadUserGuide() {
	// 	try {
	// 		setIsDownloadingUserGuide(true);
	// 		const response = await axiosInstance.get(`/download_history/download/Spatial_Tax_Panel_User_Guide.7z`, {
	// 			timeout: 0,
	// 			responseType: "arraybuffer",
	// 		}); // Added , responseType: 'arraybuffer'
	// 		saveAs(new Blob([response.data]), "Spatial_Tax_Panel_User_Guide.7z");
	// 		setIsDownloadingUserGuide(false);
	// 	} catch (error) {
	// 		setIsDownloadingUserGuide(false);
	// 		swal("Failed", "error", "error", {
	// 			buttons: false,
	// 			timer: 1000,
	// 		});
	// 	}
	// }

	// async function downloadAllFiles() {
	// 	try {
	// 		setIsDownloadingAllFiles(true);
	// 		const response = await axiosInstance.get(`/download_history/download/Spatialised_Administrative_Tax_Data_All_CSVs.zip`, {
	// 			timeout: 0,
	// 			responseType: "arraybuffer",
	// 		}); // Added , responseType: 'arraybuffer'
	// 		saveAs(new Blob([response.data]), "Spatialised_Administrative_Tax_Data_All_CSVs.zip");
	// 		setIsDownloadingAllFiles(false);
	// 	} catch (error) {
	// 		setIsDownloadingAllFiles(false);
	// 		swal("Failed", "error", "error", {
	// 			buttons: false,
	// 			timer: 1000,
	// 		});
	// 	}
	// }

	function gen_file_list() {
		setIsLoading(true);
	
		// Initialize arrays for categorized files
		let metadata_arr = filter_menu.filter((x) => x.csv_file === "Metadata.7z");
		let release_notes_arr = filter_menu.filter((x) => x.csv_file === "Release_Notes.7z");
		let ind_class_arr = filter_menu.filter((x) => x.csv_file === "SIC7_Codes.7z"); // Industry classification file
		let hex_shape_file_arr = filter_menu.filter((x) => x.csv_file === "Hexagonal_Shapefiles.7z");
		let local_shape_file_arr = filter_menu.filter((x) => x.csv_file === "Local_Municipal_Shapefiles.7z");
		let dist_shape_file_arr = filter_menu.filter((x) => x.csv_file === "District_Municipal_Shapefiles.7z");
		let prov_shape_file_arr = filter_menu.filter((x) => x.csv_file === "Province_Shapefiles.7z");
	

		// Final combined list starts with Metadata and Release Notes
		let combined_list = [...metadata_arr, ...release_notes_arr];
	
		for (let i = 0; i < filter_menu.length; i++) {
	
			// Filter Default Aggregate Data
			if (
				filter_menu[i].output_variable === selectedOutput &&
				filter_menu[i].spatial_variable === selectedSpatial &&
				filter_menu[i].temporal_variable === selectedTemporal
			) {
				if (
					selectedAggregate === "None" &&
					!metadata_arr.includes(filter_menu[i]) &&
					!release_notes_arr.includes(filter_menu[i])
				) {
					combined_list.push(filter_menu[i]);
				} else if (
					(filter_menu[i].aggregate_variable || filter_menu[i].aggregate_variable === "") && // Ensure aggregate_variable exists
					filter_menu[i].aggregate_variable.includes(selectedAggregate) &&  // Match aggregate_variable with 
					!combined_list.some((item) => item.csv_file === filter_menu[i].csv_file) // Avoid duplicates
				) {
					// Collect aggregate data separately
					combined_list.push(filter_menu[i]);
				}
			}
		}
	
		// Add spatial shape files based on the selectedSpatial
		const spatial_files = [];
		if (selectedSpatial === "Hexagon") {
			spatial_files.push(...hex_shape_file_arr);
		} else if (selectedSpatial === "Municipality") {
			spatial_files.push(...local_shape_file_arr);
		} else if (selectedSpatial === "District") {
			spatial_files.push(...dist_shape_file_arr);
		} else if (selectedSpatial === "Province") {
			spatial_files.push(...prov_shape_file_arr);
		}
	
		// Add Industry Classification file explicitly if "Industry" is in aggregation
		const industry_file = 
			selectedAggregate.includes("Industry") && ind_class_arr.length > 0 
				? ind_class_arr 
				: [];
	
		// Ensure order: Metadata > Release Notes > Industry Classification > Shape Files > Aggregate Data > Main Content
		combined_list = [
			...metadata_arr,
			...release_notes_arr,
			...industry_file, // Add Industry Classification if applicable
			...spatial_files,
			...combined_list.filter((item) =>
				!metadata_arr.includes(item) &&
				!release_notes_arr.includes(item) &&
				!spatial_files.includes(item) &&
				!industry_file.includes(item)
			),
		];
	
		// Remove duplicates and filter out empty file names
		const final_list = combined_list.filter((item, index, self) =>
			item.csv_file !== "" && self.findIndex((t) => t.csv_file === item.csv_file) === index
		);
	
		// Update state
		setfileList(final_list);
		setSelectionsSearched(true);
		setIsLoading(false);
	}
	
	
	
	
	

	function get_output_list() {
		const vret = [];
		const sortedList = filter_menu.sort((a, b) => a.output_order_number - b.output_order_number);
		for (let i = 0; i < sortedList.length; i++) {
			if (sortedList[i].req_download && sortedList[i].output_variable !== "SupplementaryData" && sortedList[i].output_variable !== "ShapeFiles" && vret.indexOf(sortedList[i].output_variable) === -1) {
				vret.push(sortedList[i].output_variable);
			}
		}
		return vret;
	}

	function get_spatial_list(outputSel) {
		const vret = [];
		for (let i = 0; i < filter_menu.length; i++) {
			if (filter_menu[i].req_download && filter_menu[i].output_variable === outputSel && vret.indexOf(filter_menu[i].spatial_variable) === -1) {
				vret.push(filter_menu[i].spatial_variable);
			}
		}
		return vret;
	}

	function get_temporal_list(outputSel, spatialSel) {
		const vret = [];
		const sortedList = filter_menu.sort((a, b) => a.temporal_order_number - b.temporal_order_number);
		for (let i = 0; i < sortedList.length; i++) {
			if (
				sortedList[i].req_download &&
				sortedList[i].output_variable === outputSel &&
				sortedList[i].spatial_variable === spatialSel &&
				vret.indexOf(sortedList[i].temporal_variable) === -1
			) {
				vret.push(sortedList[i].temporal_variable);
			}
		}
		return vret;
	}

	function get_aggregate_list(outputSel, spatialSel, temporalSel) {
		const vret = [];
		const sortedList = filter_menu.sort((a, b) => a.aggregate_order_number - b.aggregate_order_number);
		for (let i = 0; i < sortedList.length; i++) {
			if (
				sortedList[i].req_download &&
				sortedList[i].output_variable === outputSel &&
				sortedList[i].spatial_variable === spatialSel &&
				sortedList[i].temporal_variable === temporalSel &&
				sortedList[i].aggregate_variable != null &&
				vret.indexOf(sortedList[i].aggregate_variable) === -1
			) {
				vret.push(sortedList[i].aggregate_variable);
			}
		}
		return vret;
	}

	function handleOutputSelect(e) {
		const outputSel = e.target.value;
		const spatialList = get_spatial_list(outputSel);
		const temporalList = get_temporal_list(outputSel, spatialList[0]);
		const aggregateList = get_aggregate_list(outputSel, spatialList[0], temporalList[0]);
		setselectedOutput(outputSel);
		setlistSpatial(spatialList);
		setlistTemporal(temporalList);
		setlistAggregate(aggregateList);
		setselectedSpatial(spatialList[0]);
		setselectedTemporal(temporalList[0]);
		setselectedAggregate(aggregateList[0]);
		setSelectionsSearched(false);
	}

	function handleSpatialSelect(e) {
		const spatialSel = e.target.value;
		const temporalList = get_temporal_list(selectedOutput, spatialSel);
		const aggregateList = get_aggregate_list(selectedOutput, spatialSel, temporalList[0]);
		setselectedSpatial(spatialSel);
		setlistTemporal(temporalList);
		setlistAggregate(aggregateList);
		setselectedTemporal(temporalList[0]);
		setselectedAggregate(aggregateList[0]);
		setSelectionsSearched(false);
	}

	function handleTemporalSelect(e) {
		const temporalSel = e.target.value;
		const aggregateList = get_aggregate_list(selectedOutput, selectedSpatial, temporalSel);
		setselectedTemporal(temporalSel);
		setlistAggregate(aggregateList);
		setselectedAggregate(aggregateList[0]);
		setSelectionsSearched(false);
	}

	function handleAggregateSelect(e) {
		const aggregateSel = e.target.value;
		setselectedAggregate(aggregateSel);
		setSelectionsSearched(false);
	}

	const contentOptions = Object.freeze({
		FIND_FILES: "findFiles",
		ALL_FILES: "allFiles",
	});

	// const allDownloadFiles = () => {
	// 	return filter_menu
	// 		.filter((x) => x.csv_file !== "" && !x.excluded)
	// 		.sort(function (x, y) {
	// 			return x.csv_file == SupplementaryData ? -1 : y == SupplementaryData ? 1 : 0;
	// 		});
	// };

	const findColumns = useMemo(() => [
		{
			Header: "Short Description",
			accessor: "short_description",
		},
		{
			Header: "Filename",
			accessor: "csv_file",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.output} placement='top'>
					<div>Output Variable</div>
				</Tooltip>
			),
			accessor: "output_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.spatial} placement='top'>
					<div>Spatial Variable</div>
				</Tooltip>
			),
			accessor: "spatial_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.temporal} placement='top'>
					<div>Temporal Variable</div>
				</Tooltip>
			),
			accessor: "temporal_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.aggregation} placement='top'>
					<div>Aggregation Variable</div>
				</Tooltip>
			),
			accessor: "aggregate_variable",
		},
		{
			Header: "Variable Names",
			accessor: "variable_names",
		},
		{
			Header: "Download",
			id: "id",
			accessor: "csv_file",
			Cell: (props) => (
				<span>
					{isLoggedIn ? (
						<DownloadFile file={props.value} />
					) : (
						<a className='text-ccre-green-900 hover:underline' href={`${routeConstants.LOGIN}${window.location.pathname}`}>
							Login
						</a>
					)}
				</span>
			),
		},
	]);

	const allColumns = useMemo(() => [
		{
			Header: "Short Description",
			accessor: "short_description",
		},
		{
			Header: "Filename",
			accessor: "csv_file",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.output} placement='top'>
					<div>Output Variable</div>
				</Tooltip>
			),
			accessor: "output_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.spatial} placement='top'>
					<div>Spatial Variable</div>
				</Tooltip>
			),
			accessor: "spatial_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.temporal} placement='top'>
					<div>Temporal Variable</div>
				</Tooltip>
			),
			accessor: "temporal_variable",
		},
		{
			Header: (
				<Tooltip title={titleTooltip.aggregation} placement='top'>
					<div>Aggregation Variable</div>
				</Tooltip>
			),
			accessor: "aggregate_variable",
		},
		{
			Header: "Variable Names",
			accessor: "variable_names",
		},
	]);

	return (
		<div>
			<div className='bg-stone-200 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12'>
				<div className='text-3xl px-20 py-5 font-bold'>Download Data</div>
				<div className='text-m px-20 italic'>
					<p><strong>Find Files</strong> allows you to use the Map Explorer filter search to find CSV Data files.</p>
					<p><strong>Show All File Batches</strong> allows you to download one of the following file bundles (<em>Hexagonal Data & Shapefiles, Local Municipal Data & Shapefiles, District Municipal Data & Shapefiles, Province Data & Shapefiles, SIC7 Codes, Metadata, Release Notes</em>). The <em>Metadata</em> document allows you to discover the ‘Detailed Descriptions of Datasets and Variables’ used as well as any caveats and limitations.</p>
					<p><strong>7-zip file format</strong> has been used to increase compression and reduce file sizes for download. <a href="https://www.7-zip.org/download.html" target="_blank">Download 7-zip file extractor application here.</a></p>
					<p><strong>Data Version</strong>: 5 (2025-02-07)</p>
					<p><strong>Technical support and Feedback</strong>: <a href="mailto:team@spatialtaxdata.org.za?subject=Technical support and Feedback: Spatial Tax Data Website - Downloads">team@spatialtaxdata.org.za</a></p>
					{/* <p>      
						<button type="button" onClick={() => {
							throw new Error("Sentry Test Error");
						}}
						>Break the world</button>
					</p> */}
				</div>
				<div className='text-m px-20 italic'>
					{!isLoggedIn ? (
						<span>
							Please <a className='text-ccre-green-900 hover:underline' href={`${routeConstants.REGISTER}${window.location.pathname}`}>
								register 
							</a>{" "}
							(if not already) then <a className='text-ccre-green-900 hover:underline' href={`${routeConstants.LOGIN}${window.location.pathname}`}>
								login
							</a>{" "}
							in order to download files.
						</span>
					) : ( <span></span> )} 
				</div>
				<div className='grid grid-cols-2 md:grid-cols-2 mt-8'>
					<div className='mx-auto'>
						<button onClick={() => setDisplayedContent(contentOptions.FIND_FILES)} className='btn btn-primary w-80'>
							Find Files
						</button>
					</div>
					<div className='mx-auto'>
						<button onClick={() => setDisplayedContent(contentOptions.ALL_FILES)} className='btn btn-primary w-80'>
							Show All File Batches
						</button>
					</div>
				</div>
			</div>
			{displayedContent === contentOptions.FIND_FILES && (
				<>
					<div className='my-5 px-4 md:px-0'>
						<div className='relative px-4 bg-white mx-auto sm:px-10 rounded-b-md'>
							<label className='col-3'>Output:</label>
							<select className='ml-9 mb-2 py-1 bg-gray-200' name='filterOutput' value={selectedOutput} onChange={(e) => handleOutputSelect(e)}>
								{listOutput.map((e, key) => {
									return (
										<option key={e} value={e}>
											{e}
										</option>
									);
								})}
							</select>
							<br />
							<label className='col-3'>Spatial:</label>
							<select className='ml-8 mb-2 py-1 bg-gray-200' name='filterSpatial' value={selectedSpatial} onChange={(e) => handleSpatialSelect(e)}>
								{listSpatial.map((e, key) => {
									return (
										<option key={e} value={e}>
											{e}
										</option>
									);
								})}
							</select>
							<br />
							<label className='col-3'>Temporal: </label>
							<select className='ml-3 mb-2 py-1 bg-gray-200' name='filterTemporal' value={selectedTemporal} onChange={(e) => handleTemporalSelect(e)}>
								{listTemporal.map((e, key) => {
									return (
										<option key={e} value={e}>
											{e}
										</option>
									);
								})}
							</select>
							<br />
							<label className='col-3'>Aggregate: </label>
							<select className='ml-1 py-1 bg-gray-200' name='filterAggregate' value={selectedAggregate} onChange={(e) => handleAggregateSelect(e)}>
								{listAggregate.map((e, key) => {
									return (
										<option key={e} value={e}>
											{e}
										</option>
									);
								})}
							</select>
							<div className='w-full'>
								<div className='mx-auto my-5'>
									<button
										onClick={(e) => gen_file_list(e)}
										className={`btn ${!selectionsSearched ? "btn-secondary" : "btn-disabled"}`}
										disabled={selectionsSearched}
									>
										{isLoading ? "Finding Files..." : selectionsSearched ? "Files Found" : "Find Files"}
									</button>
								</div>
								<Tooltip title={titleTooltip.metadata} placement='top' className='absolute right-11 bottom-1'>
									<span className='h-[30px] w-[30px]'>
										<img className='ml-2 cursor-pointer' src={helpQuestion} alt='metadata tooltip' />
									</span>
								</Tooltip>
							</div>
						</div>
					</div>
					<div className='px-10 mb-10 w-full'>
						<TableDisplay columns={findColumns} data={fileList} />
					</div>
				</>
			)}
			{displayedContent === contentOptions.ALL_FILES && !isLoggedIn && (
				<>
					<div className='flex justify-center mx-auto m-10 px-10'>
					<span>
							Please{" "}
							<a className='text-ccre-green-900 hover:underline' href={`${routeConstants.LOGIN}${window.location.pathname}`}>
								login
							</a>{" "}
							first in order to download any files.
						</span>
					</div>

				</>
			)}
			{displayedContent === contentOptions.ALL_FILES && isLoggedIn && (
				<>
					<div className='flex justify-center text-2xl mt-10 px-8 font-bold'>Datasets (by spatial level)</div>
					<div className='flex justify-center px-10'>
						{/* Adding download bundles as buttons */}
						{/* HexagonalData */}
						<button
							onClick={() => downloadHexagonalData()}
							className={`btn ${!isDownloadingHexagonalData ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingHexagonalData}
						>
							{isDownloadingHexagonalData ? "Downloading..." : "Hexagonal Data"}
						</button>
						{isDownloadingHexagonalData && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* LocalMunicipalData */}
						<button
							onClick={() => downloadLocalMunicipalData()}
							className={`btn ${!isDownloadingLocalMunicipalData ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingLocalMunicipalData}
						>
							{isDownloadingLocalMunicipalData ? "Downloading..." : "Local Municipal Data"}
						</button>
						{isDownloadingLocalMunicipalData && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* DistrictMunicipalData */}
						<button
							onClick={() => downloadDistrictMunicipalData()}
							className={`btn ${!isDownloadingDistrictMunicipalData ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingDistrictMunicipalData}
						>
							{isDownloadingDistrictMunicipalData ? "Downloading..." : "District Municipal Data"}
						</button>
						{isDownloadingDistrictMunicipalData && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* ProvinceData */}
						<button
							onClick={() => downloadProvinceData()}
							className={`btn ${!isDownloadingProvinceData ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingProvinceData}
						>
							{isDownloadingProvinceData ? "Downloading..." : "Province Data"}
						</button>
						{isDownloadingProvinceData && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* NationalData */}
						<button
							onClick={() => downloadNationalData()}
							className={`btn ${!isDownloadingNationalData ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingNationalData}
						>
							{isDownloadingNationalData ? "Downloading..." : "National Data"}
						</button>
						{isDownloadingNationalData && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
					</div>
					
					<div className='flex justify-center text-2xl mt-5 px-8 font-bold'>Shape files</div>
					<div className='flex justify-center px-10'>
						{/* Adding download bundles as buttons */}
						{/* HexagonalShapefiles */}
						<button
							onClick={() => downloadHexagonalShapefiles()}
							className={`btn ${!isDownloadingHexagonalShapefiles ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingHexagonalShapefiles}
						>
							{isDownloadingHexagonalShapefiles ? "Downloading..." : "Hexagonal Shapefiles"}
						</button>
						{isDownloadingHexagonalShapefiles && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* LocalMunicipalShapefiles */}
						<button
							onClick={() => downloadLocalMunicipalShapefiles()}
							className={`btn ${!isDownloadingLocalMunicipalShapefiles ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingLocalMunicipalShapefiles}
						>
							{isDownloadingLocalMunicipalShapefiles ? "Downloading..." : "Local Municipal Shapefiles"}
						</button>
						{isDownloadingLocalMunicipalShapefiles && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* DistrictMunicipalShapefiles */}
						<button
							onClick={() => downloadDistrictMunicipalShapefiles()}
							className={`btn ${!isDownloadingDistrictMunicipalShapefiles ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingDistrictMunicipalShapefiles}
						>
							{isDownloadingDistrictMunicipalShapefiles ? "Downloading..." : "District Municipal Shapefiles"}
						</button>
						{isDownloadingDistrictMunicipalShapefiles && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* ProvinceShapefiles */}
						<button
							onClick={() => downloadProvinceShapefiles()}
							className={`btn ${!isDownloadingProvinceShapefiles ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingProvinceShapefiles}
						>
							{isDownloadingProvinceShapefiles ? "Downloading..." : "Province Shapefiles"}
						</button>
						{isDownloadingProvinceShapefiles && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
					</div>

					<div className='flex justify-center text-2xl mt-5 px-8 font-bold'>Supplementary Data and Documentation</div>
					<div className='flex justify-center px-10'>
						{/* SIC7Codes */}
						<button
							onClick={() => downloadSIC7Codes()}
							className={`btn ${!isDownloadingSIC7Codes ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingSIC7Codes}
						>
							{isDownloadingSIC7Codes ? "Downloading..." : "SIC7 Codes"}
						</button>
						{isDownloadingSIC7Codes && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* Metadata */}
						<button
							onClick={() => downloadMetadata()}
							className={`btn ${!isDownloadingMetadata ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingMetadata}
						>
							{isDownloadingMetadata ? "Downloading..." : "Metadata"}
						</button>
						{isDownloadingMetadata && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* ReleaseNotes */}
						<button
							onClick={() => downloadReleaseNotes()}
							className={`btn ${!isDownloadingReleaseNotes ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingReleaseNotes}
						>
							{isDownloadingReleaseNotes ? "Downloading..." : "Release Notes"}
						</button>
						{isDownloadingReleaseNotes && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)}
						{/* Spatial Tax Panel User Guide */}
						{/* <button
							onClick={() => downloadUserGuide()}
							className={`btn ${!isDownloadingUserGuide ? "btn-secondary" : "btn-disabled"} m-5`}
							disabled={isDownloadingUserGuide}
						>
							{isDownloadingUserGuide ? "Downloading..." : "User Guide"}
						</button>
						{isDownloadingUserGuide && (
							<span className='ml-4 mt-4 spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full text-ccre-orange-900'></span>
						)} */}
					</div>

					{/* <div className='px-10 mb-10 w-full'>
						<TableDisplay columns={allColumns} data={allDownloadFiles()} />
					</div> */}
				</>
			)}
		</div>
	);
}
export default DownloadFiltersForm;
